import { scrollToElement } from '../../services/Scroll'

document.addEventListener('nette:valid', (event) => {
    if (event.defaultPrevented) {
        return
    }

    const element = event.target
    const errorContainer = element.closest('.u-errorContainer') || element.parentNode

    if (element.tagName.toLowerCase() != 'button') {
        const errorElement = errorContainer.querySelector('.InputError')
        if (errorElement) {
            errorContainer.removeChild(errorElement)
        }
    }

    errorContainer.classList.remove('has-error')
    element.classList.add('is-valid')
    element.classList.remove('is-invalid')
})

document.addEventListener('nette:invalid', (event) => {
    if (event.defaultPrevented) {
        return
    }

    const element = event.target
    const errorContainer = element.closest('.u-errorContainer') || element.parentNode

    let errorElement = errorContainer.querySelector('.InputError')

    if (!errorElement) {
        errorElement = document.createElement('div')
        errorElement.className = 'InputError'
    }

    errorElement.innerHTML = element.getAttribute('data-nette-error')
    errorContainer.appendChild(errorElement)
    errorContainer.classList.add('has-error')
    element.classList.remove('is-valid')
    element.classList.add('is-invalid')
})

document.addEventListener('nette:form:valid', (event) => {})
document.addEventListener('nette:form:invalid', (event) => {
    const form = event.target

    if (form.dataset.errorAutoscroll === 'false') {
        return
    }

    const error = form.querySelector('.InputError')
    const target = error ? error.closest('.FormItem') : null
    scrollToElement(target ? target : form, -120)
})
