// import ScrollService from './NativeScroll'
import ScrollService from './VirtualScroll'

ScrollService.getScrollOffset = ({ element }) => {
    const scrollOffset = ScrollService.getScroll().y
    const header = document.querySelector('.Header')
    const headerRect = header.getBoundingClientRect()
    return headerRect.top + headerRect.height + 10 + scrollOffset
}

export const disableScroll = () => ScrollService.disableScroll()
export const enableScroll = () => ScrollService.enableScroll()
export const getScroll = () => ScrollService.getScroll()
export const getScrollTop = () => ScrollService.getScrollTop()
export const scrollToElement = (element, options) => ScrollService.scrollToElement(element, options)

export default ScrollService
